import ehed1 from "../assets/images/Completed-Project/Ehed-Building/Rectangle 280.jpg"
import ehed2 from "../assets/images/Completed-Project/Ehed-Building/Rectangle 281.jpg"
import ehed3 from "../assets/images/Completed-Project/Ehed-Building/Rectangle 284.jpg"
import uc1 from "../assets/images/Completed-Project/UC-Shopping-Complex/Rectangle 369.jpg"
import uc2 from "../assets/images/Completed-Project/UC-Shopping-Complex/Rectangle 387.jpg"
import uc3 from "../assets/images/Completed-Project/UC-Shopping-Complex/Rectangle 405.jpg"
import uc4 from "../assets/images/Completed-Project/UC-Shopping-Complex/Rectangle 423.jpg"
import uc5 from "../assets/images/Completed-Project/UC-Shopping-Complex/Rectangle 460.jpg"
import uc6 from "../assets/images/Completed-Project/UC-Shopping-Complex/Rectangle 465.jpg"
import suthagar1 from "../assets/images/Completed-Project/Suthagar-Home/Rectangle 310.jpg"
import suthagar2 from "../assets/images/Completed-Project/Suthagar-Home/Rectangle 316.jpg"
import suthagar3 from "../assets/images/Completed-Project/Suthagar-Home/Rectangle 322.jpg"
import suthagar4 from "../assets/images/Completed-Project/Suthagar-Home/Rectangle 328.jpg"
import suthagar5 from "../assets/images/Completed-Project/Suthagar-Home/Rectangle 334.jpg"
import suthagar6 from "../assets/images/Completed-Project/Suthagar-Home/Rectangle 464.jpg"
import uthayan1 from "../assets/images/Completed-Project/Account-Uthayan-Home/Rectangle 381.jpg"
import uthayan2 from "../assets/images/Completed-Project/Account-Uthayan-Home/Rectangle 399.jpg"
import uthayan3 from "../assets/images/Completed-Project/Account-Uthayan-Home/Rectangle 417.jpg"
import uthayan4 from "../assets/images/Completed-Project/Account-Uthayan-Home/Rectangle 435.jpg"
import uthayan5 from "../assets/images/Completed-Project/Account-Uthayan-Home/Rectangle 453.jpg"
import uthayan6 from "../assets/images/Completed-Project/Account-Uthayan-Home/Rectangle 459.jpg"
import uthayan7 from "../assets/images/Completed-Project/Account-Uthayan-Home/Rectangle 466.jpg"
import remi1 from "../assets/images/Completed-Project/Remi-Home/Rectangle 372.jpg"
import remi2 from "../assets/images/Completed-Project/Remi-Home/Rectangle 390.jpg"
import remi3 from "../assets/images/Completed-Project/Remi-Home/Rectangle 408.jpg"
import remi4 from "../assets/images/Completed-Project/Remi-Home/Rectangle 426.jpg"
import remi5 from "../assets/images/Completed-Project/Remi-Home/Rectangle 463.jpg"
import lenin1 from "../assets/images/Completed-Project/Lenin-Home/Rectangle 380.jpg"
import lenin2 from "../assets/images/Completed-Project/Lenin-Home/Rectangle 398.jpg"
import lenin3 from "../assets/images/Completed-Project/Lenin-Home/Rectangle 407.jpg"
import lenin4 from "../assets/images/Completed-Project/Lenin-Home/Rectangle 416.jpg"
import lenin5 from "../assets/images/Completed-Project/Lenin-Home/Rectangle 425.jpg"
import lenin6 from "../assets/images/Completed-Project/Lenin-Home/Rectangle 434.jpg"
import lenin7 from "../assets/images/Completed-Project/Lenin-Home/Rectangle 443.jpg"
import lenin8 from "../assets/images/Completed-Project/Lenin-Home/Rectangle 452.jpg"
import quadeloop1 from "../assets/images/Completed-Project/Quadeloop-Church/Rectangle 309.jpg"
import quadeloop2 from "../assets/images/Completed-Project/Quadeloop-Church/Rectangle 315.jpg"
import tharsini1 from "../assets/images/Completed-Project/Tharsini-Home/Rectangle 307.jpg"
import tharsini2 from "../assets/images/Completed-Project/Tharsini-Home/Rectangle 313.jpg"
import tharsini3 from "../assets/images/Completed-Project/Tharsini-Home/Rectangle 319.jpg"
import tharsini4 from "../assets/images/Completed-Project/Tharsini-Home/Rectangle 325.jpg"
import tharsini5 from "../assets/images/Completed-Project/Tharsini-Home/Rectangle 331.jpg"
import sivakumar1 from "../assets/images/Completed-Project/Dr Sivakumar-Home/Rectangle 338.jpg"
import sivakumar2 from "../assets/images/Completed-Project/Dr Sivakumar-Home/Rectangle 344.jpg"
import sivakumar3 from "../assets/images/Completed-Project/Dr Sivakumar-Home/Rectangle 350.jpg"
import sivakumar4 from "../assets/images/Completed-Project/Dr Sivakumar-Home/Rectangle 356.jpg"
import sivakumar5 from "../assets/images/Completed-Project/Dr Sivakumar-Home/Rectangle 362.jpg"
import bratlee1 from "../assets/images/Completed-Project/Bratlee-Home/Rectangle 311.jpg"
import bratlee2 from "../assets/images/Completed-Project/Bratlee-Home/Rectangle 317.jpg"
import kuchchavelihotel1 from "../assets/images/Completed-Project/Kuchchaveli-Hotel/Rectangle 384.jpg"
import kuchchavelihotel2 from "../assets/images/Completed-Project/Kuchchaveli-Hotel/Rectangle 402.jpg"
import kuchchavelihotel3 from "../assets/images/Completed-Project/Kuchchaveli-Hotel/Rectangle 420.jpg"
import kuchchavelihotel4 from "../assets/images/Completed-Project/Kuchchaveli-Hotel/Rectangle 438.jpg"
import farmhouse1 from "../assets/images/Completed-Project/Farm-House/Rectangle 377.jpg"
import farmhouse2 from "../assets/images/Completed-Project/Farm-House/Rectangle 395.jpg"
import farmhouse3 from "../assets/images/Completed-Project/Farm-House/Rectangle 413.jpg"
import farmhouse4 from "../assets/images/Completed-Project/Farm-House/Rectangle 431.jpg"
import farmhouse5 from "../assets/images/Completed-Project/Farm-House/Rectangle 437.jpg"
import farmhouse6 from "../assets/images/Completed-Project/Farm-House/Rectangle 449.jpg"
import farmhouse7 from "../assets/images/Completed-Project/Farm-House/Rectangle 455.jpg"
import selvanayagapuramhospital from "../assets/images/Completed-Project/Selvanayagapuram-Hospital/Rectangle 468.jpg"
import selvanayagapuramhospital1 from "../assets/images/Completed-Project/Selvanayagapuram-Hospital/Rectangle 479.jpg"
import selvanayagapuramhospital2 from "../assets/images/Completed-Project/Selvanayagapuram-Hospital/Rectangle 480.jpg"
import selvanayagapuramhospital3 from "../assets/images/Completed-Project/Selvanayagapuram-Hospital/Rectangle 481.jpg"
import selvanayagapuramhospital4 from "../assets/images/Completed-Project/Selvanayagapuram-Hospital/Rectangle 482.jpg"
import jaanuhome1 from "../assets/images/Completed-Project/Jaanu-Home/Rectangle 341.jpg"
import jaanuhome2 from "../assets/images/Completed-Project/Jaanu-Home/Rectangle 347.jpg"
import jaanuhome3 from "../assets/images/Completed-Project/Jaanu-Home/Rectangle 353.jpg"
import jaanuhome4 from "../assets/images/Completed-Project/Jaanu-Home/Rectangle 359.jpg"
import jaanuhome5 from "../assets/images/Completed-Project/Jaanu-Home/Rectangle 365.jpg"
import jaanuhome6 from "../assets/images/Completed-Project/Jaanu-Home/Rectangle 457.jpg"
import  bamahome1 from "../assets/images/Completed-Project/Bama-Home/Rectangle 378.jpg"
import  bamahome2 from "../assets/images/Completed-Project/Bama-Home/Rectangle 396.jpg"
import palayotruhome1 from "../assets/images/Completed-Project/Palayotru-Home/Rectangle 375.jpg"
import palayotruhome2 from "../assets/images/Completed-Project/Palayotru-Home/Rectangle 393.jpg"
import palayotruhome3 from "../assets/images/Completed-Project/Palayotru-Home/Rectangle 411.jpg"
import palayotruhome4 from "../assets/images/Completed-Project/Palayotru-Home/Rectangle 429.jpg"
import palayotruhome5 from "../assets/images/Completed-Project/Palayotru-Home/Rectangle 447.jpg"
import palayotruhome6 from "../assets/images/Completed-Project/Palayotru-Home/Rectangle 458.jpg"
import rosemedroad1 from "../assets/images/Completed-Project/Rosemed Road/Rectangle 342.jpg"
import rosemedroad2 from "../assets/images/Completed-Project/Rosemed Road/Rectangle 348.jpg"
import queenroad1 from "../assets/images/Completed-Project/Queen-Road/Rectangle 339.jpg"
import queenroad2 from "../assets/images/Completed-Project/Queen-Road/Rectangle 345.jpg"
import queenroad3 from "../assets/images/Completed-Project/Queen-Road/Rectangle 351.jpg"
import queenroad4 from "../assets/images/Completed-Project/Queen-Road/Rectangle 357.jpg"
import roshan1 from "../assets/images/Completed-Project/Roshan-Building/Rectangle 312.jpg"
import roshan2 from "../assets/images/Completed-Project/Roshan-Building/Rectangle 318.jpg"
import roshan3 from "../assets/images/Completed-Project/Roshan-Building/Rectangle 336.jpg"
import roshan4 from "../assets/images/Completed-Project/Roshan-Building/Rectangle 368.jpg"
import roshan5 from "../assets/images/Completed-Project/Roshan-Building/Rectangle 386.jpg"
import roshan6 from "../assets/images/Completed-Project/Roshan-Building/Rectangle 404.jpg"
import roshan7 from "../assets/images/Completed-Project/Roshan-Building/Rectangle 422.jpg"
import roshan8 from "../assets/images/Completed-Project/Roshan-Building/Rectangle 440.jpg"
import roshan9 from "../assets/images/Completed-Project/Roshan-Building/Rectangle 461.jpg"
import roshan10 from "../assets/images/Completed-Project/Roshan-Building/Rectangle 462.jpg"
import cc1 from "../assets/images/Completed-Project/CC-Akka/Rectangle 374.jpg"
import cc2 from "../assets/images/Completed-Project/CC-Akka/Rectangle 392.jpg"
import cc3 from "../assets/images/Completed-Project/CC-Akka/Rectangle 410.jpg"
import kumar1 from "../assets/images/Completed-Project/Kumar-Home/Rectangle 308.jpg"
import kumar2 from "../assets/images/Completed-Project/Kumar-Home/Rectangle 314.jpg"
import havelock1 from "../assets/images/Completed-Project/Havelock/Rectangle 473.jpg"
import havelock2 from "../assets/images/Completed-Project/Havelock/Rectangle 474.jpg"
import tata1 from "../assets/images/Completed-Project/Tata/Rectangle 471.jpg"
import altair1 from "../assets/images/Completed-Project/Altair/Rectangle 337.jpg"
import altair2 from "../assets/images/Completed-Project/Altair/Rectangle 343.jpg"
import altair3 from "../assets/images/Completed-Project/Altair/Rectangle 349.jpg"
import altair4 from "../assets/images/Completed-Project/Altair/Rectangle 355.jpg"
import sankrilla1 from "../assets/images/Completed-Project/Sankrilla/Rectangle 475.jpg"
import sankrilla2 from "../assets/images/Completed-Project/Sankrilla/Rectangle 476.jpg"
import sankrilla3 from "../assets/images/Completed-Project/Sankrilla/Rectangle 477.jpg"
import sankrilla4 from "../assets/images/Completed-Project/Sankrilla/Rectangle 478.jpg"
import itc1 from "../assets/images/Completed-Project/ITC-Hotel/Rectangle 337.jpg"
import itc2 from "../assets/images/Completed-Project/ITC-Hotel/Rectangle 343.jpg"
import itc3 from "../assets/images/Completed-Project/ITC-Hotel/Rectangle 349.jpg"
import itc4 from "../assets/images/Completed-Project/ITC-Hotel/Rectangle 355.jpg"
import itc5 from "../assets/images/Completed-Project/ITC-Hotel/Rectangle 361.jpg"
import common from "../assets/images/RandomHouse.jpg"


const completedProject = [
    {
        "id": 1,
        "title": "Blackpool Commercial Building",
        "category": "Private",
        "work_place": {
            "client": "Mr. Roshan Rajappa",
            "projectValue": 190,
            "initiation": "Jul 01, 2022",
            "completion": "Aug 01, 2023",
            "supervisors": [
                { "name": "Grace Construction"}
            ]
        },
        "address": "Trincomalee",
        "images": [roshan1, roshan2, roshan3, roshan4, roshan5, roshan6, roshan7, roshan8, roshan9, roshan10]
    },
    {
        "id": 2,
        "title": "EHED Building",
        "category": "Private",
        "work_place": {
            "client": "EHED Office",
            "projectValue": 15,
            "initiation": "Dec 01, 2022",
            "completion": "Jun 01, 2023",
            "supervisors": [
                { "name": "Grace Construction" }
            ]
        },
        "address": "Trincomalee",
        "images": [ehed1, ehed2,ehed3]
    },
    {
        "id": 3,
        "title": "UC Supermarket",
        "category": "Private",
        "work_place": {
            "client": "---",
            "projectValue": 23.1,
            "initiation": "Oct 07, 2020",
            "completion": "Jul 06, 2021",
            "supervisors": [
                { "name": "Grace Construction"}
            ]
        },
        "address": "Trincomalee",
        "images": [uc1, uc2,uc3,uc4,uc5,uc6]
    },
    // {
    //     "id": 4,
    //     "title": "---",
    //     "category": "Private",
    //     "work_place": {
    //         "client": "---",
    //         "projectValue": "---",
    //         "initiation": "---",
    //         "completion": "---",
    //         "supervisors": [
    //             { "name": "Grace Construction" }
    //         ]
    //     },
    //     "address": "Vanniyar street",
    //     "images": [dummy1, dummy2,dummy3,dummy4]
    // },
    {
        "id": 5,
        "title": "Proposed House for Ms. Supajini",
        "category": "Private",
        "work_place": {
            "client": "Ms. Supajini",
            "projectValue": 20,
            "initiation": "Aug 01, 2022",
            "completion": "Feb 01, 2023",
            "supervisors": [
                { "name": "Grace Construction" }
            ]
        },
        "address": "Trincomalee",
        "images": [cc1, cc2, cc3]
    },
    {
        "id": 6,
        "title": "Proposed House for Mr & Mrs. Niroshan",
        "category": "Private",
        "work_place": {
            "client": "Mr & Mrs. Niroshan",
            "projectValue": 10,
            "initiation": "Apr 01, 2022",
            "completion": "Oct 01, 2022",
            "supervisors": [
                { "name": "Grace Construction" }
            ]
        },
        "address": "Trincomalee",
        "images": [common]
    },
    {
        "id": 7,
        "title": "Proposed House for Mr. Suthagar",
        "category": "Private",
        "work_place": {
            "client": "Mr. K. Suthagaran",
            "projectValue": 40,
            "initiation": "Apr 01, 2022",
            "completion": "Apr 01, 2023",
            "supervisors": [
                { "name": "Grace Construction" }
            ]
        },
        "address": "Trincomalee",
        "images": [suthagar1, suthagar2,suthagar3,suthagar4,suthagar5,suthagar6]
    },
    {
        "id": 8,
        "title": "Proposed House for Mr. Uthayarajan",
        "category": "Private",
        "work_place": {
            "client": "Mr. Uthayarajan",
            "projectValue": 14.4,
            "initiation": "Feb 01, 2021",
            "completion": "Mar 01, 2022",
            "supervisors": [
                { "name": "Grace Construction" }
            ]
        },
        "address": "Trincomalee",
        "images": [uthayan1, uthayan2,uthayan3,uthayan4,uthayan5,uthayan6,uthayan7]
    },
    {
        "id": 9,
        "title": "Proposed House for Mrs. Nilukshini",
        "category": "Private",
        "work_place": {
            "client": "Mrs. Nilukshini",
            "projectValue": 10,
            "initiation": "May 01, 2021",
            "completion": "Nov 01, 2021",
            "supervisors": [
                { "name": "Grace Construction" }
            ]
        },
        "address": "Trincomalee",
        "images": [remi1, remi2,remi3,remi4,remi5]
    },
    {
        "id": 10,
        "title": "Proposed House for Mr. Antony Nimalesh",
        "category": "Private",
        "work_place": {
            "client": "Mr. Antony Nimalesh",
            "projectValue": 8.6,
            "initiation": "Feb 01, 2022",
            "completion": "Jun 01, 2022",
            "supervisors": [
                { "name": "Grace Construction" }
            ]
        },
        "address": "Trincomalee",
        "images": [common]
    },
    {
        "id": 11,
        "title": "Proposed House for Mr. Lenin",
        "category": "Private",
        "work_place": {
            "client": "Mr. Lenin",
            "projectValue": 12,
            "initiation": "Mar 01, 2022",
            "completion": "Nov 01, 2022",
            "supervisors": [
                { "name": "Grace Construction" }
            ]
        },
        "address": "Trincomalee",
        "images": [lenin1, lenin2,lenin3,lenin4,lenin5,lenin6,lenin7,lenin8]
    },
    {
        "id": 12,
        "title": "Church Renovation - Quaderlupe Church Trincomalee",
        "category": "Private",
        "work_place": {
            "client": "Bishop of Trinco",
            "projectValue": 7.5,
            "initiation": "Oct 01, 2021",
            "completion": "Apr 01, 2022",
            "supervisors": [
                { "name": "Grace Construction" }
            ]
        },
        "address": "Trincomalee",
        "images": [quadeloop1, quadeloop2]
    },
    {
        "id": 13,
        "title": "Proposed House for Mrs. Niroshini",
        "category": "Private",
        "work_place": {
            "client": "Mrs. Niroshini",
            "projectValue": 15,
            "initiation": "Mar 01, 2022",
            "completion": "Jul 01, 2022",
            "supervisors": [
                { "name": "Grace Construction" }
            ]
        },
        "address": "Trincomalee",
        "images": [common]
    },
    {
        "id": 14,
        "title": "Proposed House for Mr. Dhilip",
        "category": "Private",
        "work_place": {
            "client": "Mr. Dhilip",
            "projectValue": 10,
            "initiation": "Apr 01, 2022",
            "completion": "Aug 01, 2022",
            "supervisors": [
                { "name": "Grace Construction" }
            ]
        },
        "address": "Trincomalee",
        "images": [kumar1, kumar2]
    },
    {
        "id": 15,
        "title": "Proposed House for Mr. S. Kanahasabai",
        "category": "Private",
        "work_place": {
            "client": "Mr. S. Kanahasabai",
            "projectValue": 25,
            "initiation": "Feb 01, 2018",
            "completion": "Aug 01, 2018",
            "supervisors": [
                { "name": "Grace Construction" }
            ]
        },
        "address": "Trincomalee",
        "images": [tharsini1, tharsini2,tharsini3,tharsini4,tharsini5]
    },
    {
        "id": 16,
        "title": "Proposed House for Dr. Sivakumar",
        "category": "Private",
        "work_place": {
            "client": "Dr. Sivakumar",
            "projectValue": 25,
            "initiation": "Oct 01, 2021",
            "completion": "Apr 01, 2022",
            "supervisors": [
                { "name": "Grace Construction" }
            ]
        },
        "address": "Trincomalee",
        "images": [sivakumar1, sivakumar2, sivakumar3, sivakumar4, sivakumar5]
    },
    // {
    //     "id": 17,
    //     "title": "Proposed House for Mr. Pragash",
    //     "category": "Private",
    //     "work_place": {
    //         "client": "Mr. Pragash",
    //         "projectValue": "---",
    //         "initiation": "---",
    //         "completion": "---",
    //         "supervisors": [
    //             { "name": "Grace Construction" }
    //         ]
    //     },
    //     "address": "Trincomalee",
    //     "images": [dummy1, dummy2,dummy3,dummy4]
    // },
    {
        "id": 18,
        "title": "Bratlee Dreem House",
        "category": "Private",
        "work_place": {
            "client": "Mr. Bratlee",
            "projectValue": 12.2,
            "initiation": "Oct 01, 2021",
            "completion": "May 01, 2022",
            "supervisors": [
                { "name": "Grace Construction" }
            ]
        },
        "address": "Trincomalee",
        "images": [bratlee1, bratlee2]
    },
    // {
    //     "id": 19,
    //     "title": "Vasanthakumar sir",
    //     "category": "Private",
    //     "work_place": {
    //         "client": "Vasanthakumar sir",
    //         "projectValue": "---",
    //         "initiation": "---",
    //         "completion": "---",
    //         "supervisors": [
    //             { "name": "Grace Construction" }
    //         ]
    //     },
    //     "address": "Trincomalee",
    //     "images": [dummy1, dummy2,dummy3,dummy4]
    // },
    {
        "id": 20,
        "title": "Kuchchaveli Hotel",
        "category": "Private",
        "work_place": {
            "client": "Kuchchaveli Hotel",
            "projectValue": 45,
            "initiation": "Mar 01, 2021",
            "completion": "Nov 01, 2021",
            "supervisors": [
                { "name": "Grace Construction" }
            ]
        },
        "address": "Kuchcaveli, Trincomalee",
        "images": [kuchchavelihotel1, kuchchavelihotel2, kuchchavelihotel3, kuchchavelihotel4]
    },
    {
        "id": 21,
        "title": "Farm House",
        "category": "Private",
        "work_place": {
            "client": "Mr. Roshan",
            "projectValue": 18.8,
            "initiation": "Aug 18, 2022",
            "completion": "Jun 25, 2023",
            "supervisors": [
                { "name": "Grace Construction" }
            ]
        },
        "address": "Trincomalee",
        "images": [farmhouse1, farmhouse2, farmhouse3, farmhouse4, farmhouse5, farmhouse6, farmhouse7]
    },
    {
        "id": 24,
        "title": "Selvanayakapuram Hospital - Stage 01 (Expansion of ETU)",
        "category": "Government",
        "work_place": {
            "client": "Provincial Director of Health service",
            "projectValue": 12.2,
            "initiation": "May 22, 2023",
            "completion": "Nov 22, 2023",
            "supervisors": [
                { "name": "Grace Construction" }
            ]
        },
        "address": "Selvanayakapuram, Trincomalee",
        "images": [selvanayagapuramhospital1, selvanayagapuramhospital2, selvanayagapuramhospital3, selvanayagapuramhospital4]
    },
    {
        "id": 25,
        "title": "Selvanayakapuram Hospital - Stage 02 (Ward at DH)",
        "category": "Government",
        "work_place": {
            "client": "Selva, Provincial Director of Health service",
            "projectValue": 4.3,
            "initiation": "Nov 12, 2023",
            "completion": "Oct 26, 2023",
            "supervisors": [
                { "name": "Grace Construction" }
            ]
        },
        "address": "Selvanayakapuram, Trincomalee",
        "images": [selvanayagapuramhospital]
    },
    {
        "id": 26,
        "title": "Selvanayakapuram Hospital - Water Tank",
        "category": "Government",
        "work_place": {
            "client": "Selva, Provincial Director of Health service",
            "projectValue": 0.6,
            "initiation": "Nov 12, 2023",
            "completion": "Oct 26, 2023",
            "supervisors": [
                { "name": "Grace Construction" }
            ]
        },
        "address": "Selvanayakapuram, Trincomalee",
        "images": [common]
    },
    {
        "id": 27,
        "title": "Proposed House for Mr. Prasath",
        "category": "Private",
        "work_place": {
            "client": "Mr. Prasath",
            "projectValue": 15,
            "initiation": "Jan 01, 2023",
            "completion": "Jan 01, 2024",
            "supervisors": [
                { "name": "Grace Construction" }
            ]
        },
        "address": "Linkanagar, Trincomalee",
        "images": [common]
    },
    {
        "id": 28,
        "title": "Proposed House for Dr. Thanushanth",
        "category": "Private",
        "work_place": {
            "client": "Dr. Thanushanth",
            "projectValue": 12,
            "initiation": "Jan 01, 2019",
            "completion": "Jun 01, 2019",
            "supervisors": [
                { "name": "Grace Construction" }
            ]
        },
        "address": "Trincomalee",
        "images": [jaanuhome1, jaanuhome2, jaanuhome3, jaanuhome4, jaanuhome5, jaanuhome6]
    },
    // {
    //     "id": 29,
    //     "title": "Proposed House for Gowtham",
    //     "category": "Private",
    //     "work_place": {
    //         "client": "Gowtham",
    //         "projectValue": "---",
    //         "initiation": "---",
    //         "completion": "---",
    //         "supervisors": [
    //             { "name": "Grace Construction" }
    //         ]
    //     },
    //     "address": "Linkanagar, Trincomalee",
    //     "images": [dummy1, dummy2,dummy3,dummy4]
    // },
    {
        "id": 30,
        "title": "Proposed House for Ms. Bama",
        "category": "Private",
        "work_place": {
            "client": "Ms. Bama",
            "projectValue": 8.5,
            "initiation": "Mar 01, 2021",
            "completion": "Jul 01, 2021",
            "supervisors": [
                { "name": "Grace Construction" }
            ]
        },
        "address": "Trincomalee",
        "images": [bamahome1, bamahome2]
    },
    {
        "id": 32,
        "title": "Paalaiyotru Home",
        "category": "Private",
        "work_place": {
            "client": "Paalaiyotru Home",
            "projectValue": 15,
            "initiation": "Aug 01, 2019",
            "completion": "Aug 01, 2020",
            "supervisors": [
                { "name": "Grace Construction" }
            ]
        },
        "address": "Paalaiyotru, Trincomalee",
        "images": [palayotruhome1, palayotruhome2, palayotruhome3, palayotruhome4, palayotruhome5, palayotruhome6]
    },
    {
        "id": 33,
        "title": "Construction of two storied math lab with class room building",
        "category": "Government",
        "work_place": {
            "client": "T/ Seruwila Maha Vidyalayam",
            "projectValue": 14.7,
            "initiation": "Oct 10, 2022",
            "completion": "Jan 07, 2023",
            "supervisors": [
                { "name": "Grace Construction" }
            ]
        },
        "address": "Seruwila",
        "images": [common]
    },
    {
        "id": 34,
        "title": "Construction of two storied math lab with class room building - Stage 02",
        "category": "Government",
        "work_place": {
            "client": "T/ Seruwila Maha Vidyalayam",
            "projectValue": 8,
            "initiation": "Oct 12, 2023",
            "completion": "Dec 12, 2023",
            "supervisors": [
                { "name": "Grace Construction" }
            ]
        },
        "address": "T/M/Bharathi vidyalayam",
        "images": [common]
    },
    {
        "id": 35,
        "title": "Construction of two storied math lab with class room building",
        "category": "Government",
        "work_place": {
            "client": "T/M/ Bharathy Maha Vidyalayam",
            "projectValue": 14.7,
            "initiation": "Oct 10, 2022",
            "completion": "Jan 07, 2023",
            "supervisors": [
                { "name": "Grace Construction" }
            ]
        },
        "address": "Kilivetty, Trincomalee",
        "images": [common]
    },
    // {
    //     "id": 36,
    //     "title": "---**",
    //     "category": "Government",
    //     "work_place": {
    //         "client": "Chinabay",
    //         "projectValue": "---",
    //         "initiation": "---",
    //         "completion": "---",
    //         "supervisors": [
    //             { "name": "---", "role": "{Civil engineer}" }
    //         ]
    //     },
    //     "address": "---",
    //     "images": [dummy1, dummy2,dummy3,dummy4]
    // },
    // {
    //     "id": 37,
    //     "title": "4th mile post - Sumedangava**",
    //     "category": "Government",
    //     "work_place": {
    //         "client": "---",
    //         "projectValue": "---",
    //         "initiation": "---",
    //         "completion": "---",
    //         "supervisors": [
    //             { "name": "---", "role": "{Civil engineer}" }
    //         ]
    //     },
    //     "address": "---",
    //     "images": [dummy1, dummy2,dummy3,dummy4]
    // },
    {
        "id": 38,
        "title": "Varodayanagar - Councling building Construction of library council secretary at Eastern Provincial Council",
        "category": "Government",
        "work_place": {
            "client": "Department of Buildings",
            "projectValue": 4.8,
            "initiation": "Oct 22, 2019",
            "completion": "Jan 05, 2020",
            "supervisors": [
                { "name": "Grace Construction"}
            ]
        },
        "address": "Varodayanagar, Trincomalee",
        "images": [common]
    },
    {
        "id": 39,
        "title": "Havelock city",
        "category": "Subcontract",
        "work_place": {
            "client": "---",
            "projectValue": "---",
            "initiation": "---",
            "completion": "---",
            "supervisors": [
                { "name": "---", "role": "{Civil engineer}" }
            ]
        },
        "address": "---",
        "images": [havelock1, havelock2]
    },
    {
        "id": 40,
        "title": "Shanrgrila hotel",
        "category": "Subcontract",
        "work_place": {
            "client": "---",
            "projectValue": "---",
            "initiation": "---",
            "completion": "---",
            "supervisors": [
                { "name": "---", "role": "{Civil engineer}" }
            ]
        },
        "address": "---",
        "images": [sankrilla1, sankrilla2, sankrilla3, sankrilla4]
    },
    {
        "id": 41,
        "title": "Altare residence",
        "category": "Subcontract",
        "work_place": {
            "client": "---",
            "projectValue": "---",
            "initiation": "---",
            "completion": "---",
            "supervisors": [
                { "name": "---", "role": "{Civil engineer}" }
            ]
        },
        "address": "---",
        "images": [altair1, altair2, altair3, altair4]
    },
    {
        "id": 42,
        "title": "Tata housing project",
        "category": "Subcontract",
        "work_place": {
            "client": "---",
            "projectValue": "---",
            "initiation": "---",
            "completion": "---",
            "supervisors": [
                { "name": "---", "role": "{Civil engineer}" }
            ]
        },
        "address": "---",
        "images": [tata1]
    },
    {
        "id": 43,
        "title": "ITC hotel & Residence",
        "category": "Subcontract",
        "work_place": {
            "client": "---",
            "projectValue": "---",
            "initiation": "---",
            "completion": "---",
            "supervisors": [
                { "name": "---", "role": "{Civil engineer}" }
            ]
        },
        "address": "---",
        "images": [itc1, itc2, itc3, itc4, itc5]
    },
    // {
    //     "id": 44,
    //     "title": "---",
    //     "category": "Subcontract",
    //     "work_place": {
    //         "client": "---",
    //         "projectValue": "---",
    //         "initiation": "---",
    //         "completion": "---",
    //         "supervisors": [
    //             { "name": "---", "role": "{Civil engineer}" }
    //         ]
    //     },
    //     "address": "Address 606",
    //     "images": [dummy1, dummy2,dummy3,dummy4]
    // },
    {
        "id": 45,
        "title": "Rosemed Road",
        "category": "Subcontract",
        "work_place": {
            "client": "---",
            "projectValue": "---",
            "initiation": "---",
            "completion": "---",
            "supervisors": [
                { "name": "---", "role": "{Civil engineer}" }
            ]
        },
        "address": "---",
        "images": [rosemedroad1, rosemedroad2]
    },
    {
        "id": 46,
        "title": "Queen's Road",
        "category": "Subcontract",
        "work_place": {
            "client": "---",
            "projectValue": "---",
            "initiation": "---",
            "completion": "---",
            "supervisors": [
                { "name": "---", "role": "{Civil engineer}" }
            ]
        },
        "address": "---",
        "images": [queenroad1, queenroad2, queenroad3, queenroad4]
    },
    {
        "id": 47,
        "title": "Eastern University",
        "category": "Government",
        "work_place": {
            "client": "Eastern University",
            "projectValue": 12,
            "initiation": "Jan 01, 2015",
            "completion": "May 01, 2015",
            "supervisors": [
                { "name": "Grace Construction"}
            ]
        },
        "address": "Trincomalee",
        "images": [common]
    }

];


export default completedProject;
