import Niloge from "./../assets/Staffs/Niloge.jpg";
import Sathianathan from "./../assets/Staffs/Sathi.jpg";
import Rayan from "./../assets/Staffs/Rayan.jpg";
import Ahilan from "./../assets/Staffs/Ahilan.jpg";
import Arunram from "./../assets/Staffs/Arunram.jpg";
import Fawas from "./../assets/Staffs/Fawas.jpg";
import Gobishankar from "./../assets/Staffs/Gobishankar.jpg";
import Hashan from "./../assets/Staffs/Hashan.jpg";
import Insam from "./../assets/Staffs/Insam.jpg";
import Jayalakshan from "./../assets/Staffs/Jeyalakshan.jpg";
import Jeyanther from "./../assets/Staffs/Jayanther.jpg";
import Keshanth from "./../assets/Staffs/John_Keshanth.jpg";
import Kajanan from "./../assets/Staffs/Kajanan.jpg";
import Kulatharshan from "./../assets/Staffs/Kulatharshan.jpg";
import Lakshani from "./../assets/Staffs/Lakshani.jpg";
import Luksha from "./../assets/Staffs/Luksha.jpg";
import Nadaraja from "./../assets/Staffs/Nadaraja.jpg";
import Nithuha from "./../assets/Staffs/Nithuha.jpg";
import Partheepan from "./../assets/Staffs/Partheepan.jpg";
import Rachusthan from "./../assets/Staffs/Rachsuthan.jpg";
import Sahan from "./../assets/Staffs/Sahan.jpg";
import Sinnarasa from "./../assets/Staffs/Sinnarasa.jpg";
import Template from "./../assets/Staffs/Template.png";
import Vasanthan from "./../assets/Staffs/Vasanthan.jpg";
import Vijayathasan from "./../assets/Staffs/Vijayathasan.jpg";
import Vinojan from "./../assets/Staffs/Vinojan.jpg";
import John from "./../assets/Staffs/John.jpg";

const Directors = [
    {
        "id": 1,
        "name": "Bertin Jude Niloj",
        "role": "Managing Director",
        "degree": '"At Grace Construction, every build reflects our commitment to craftsmanship and innovation."',
        "image": Niloge
    },
    {
        "id": 2,
        "name": "Eng. Thangathurai Brammananda",
        "role": "General Manager",
        "degree": '"Leading with integrity, we drive excellence and innovation at Grace Construction."',
        "image": Template
    }

];

const Heads = [
    {
        "id": 1,
        "name": "Eng. Sasanka",
        "role": "Structural Engineer",
        "degree": "BSc Hons Civil Engineering, University of Jaffna",
        "image": Template
    },
    {
        "id": 2,
        "name": "Subendran Jayalakshan",
        "role": "Project Manager",
        "degree": "BSc (Hons) in Civil Engineering, MSc (Eng) in Geotechnical Engineering (reading)",
        "image": Jayalakshan
    },
    {
        "id": 3,
        "name": "Thiyageswaran Gobishankar",
        "role": "Project Manager",
        "degree": "BSc (Hons) in Quantity Surveying",
        "image": Gobishankar
    },
    {
        "id": 4,
        "name": "Puvanendran Jayanther",
        "role": "Project Manager",
        "degree": "NDCT, NCT (Civil)",
        "image": Jeyanther
    },
    {
        "id": 5,
        "name": "Sivajathurshana Vijayathasan",
        "role": "Project Manager",
        "degree": "BSc (Hons) in Engineering (Civil Engineering)",
        "image": Vijayathasan
    },
    {
        "id": 6,
        "name": "Rajaratnam Sathianathan",
        "role": "Executive Engineer",
        "degree": "",
        "image": Sathianathan
    }

];

const Architects = [
    {
        "id": 1,
        "name": "Keneth Keethaponkalan",
        "role": "Architect",
        "degree": "Chartered Architect",
        "image": Template
    },
    {
        "id": 2,
        "name": "Insam",
        "role": "Architect Designer",
        "degree": "",
        "image": Insam
    },
    {
        "id": 3,
        "name": "Ahilan Pahirathan",
        "role": "Engineering Draughtsman",
        "degree": "",
        "image": Ahilan
    }


];

const EngineeringTrainees = [
    {
        "id": 1,
        "name": "Jeyakumar John Kesanth",
        "role": "Engineering Trainee",
        "degree": "BSc (Hons) in Engineering (Civil Engineering)",
        "image": John
    },
    {
        "id": 2,
        "name": "Perinparasa Vinojan",
        "role": "Engineering Trainee",
        "degree": "BSc (Hons) in Engineering (Civil Engineering)",
        "image": Vinojan
    },
    {
        "id": 3,
        "name": "Veeraikulasingam Rachsuthan",
        "role": "Engineering Trainee",
        "degree": "BSc (Hons) in Engineering (Civil & Environmental Engineering)",
        "image": Rachusthan
    }
];

const TechnicalOfficers = [
    {
        "id": 1,
        "name": "Vasanthan",
        "role": "Technical Officer",
        "degree": "BSc Hons Civil Engineering, University of Jaffna",
        "image": Vasanthan
    },
    {
        "id": 2,
        "name": "Balachandran Shwethaan",
        "role": "Technical Officer",
        "degree": "NCT in Civil Engineering (NVQ5)",
        "image": Template
    },
    {
        "id": 3,
        "name": "Kamalanathan Kajanan",
        "role": "Technical Officer",
        "degree": "NCT Civil Engineering",
        "image": Kajanan
    },
    {
        "id": 4,
        "name": "Perinparasa Birunthagan",
        "role": "Technical Officer",
        "degree": "NCT (National Diploma Construction Technology)",
        "image": Template
    }
]

const Surveyers = [
    {
        "id": 1,
        "name": "Mrs. Logitha Pantheepan",
        "role": "Quantity Surveying",
        "degree": "BSc (Hons) in Quantity Surveying",
        "image": Partheepan
    },
    {
        "id": 2,
        "name": "Kulenthran Kulatharshan",
        "role": "Quantity Surveying",
        "degree": "BSc (Hons) in Quantity Surveying",
        "image": Kulatharshan
    }
];

const NDT = [
    {
        "id": 1,
        "name": "Mohamed Hashan",
        "role": "NDT",
        "degree": "NDT in Civil Engineering, BSc (Hons) in Civil Engineering (reading)",
        "image": Hashan
    },
    {
        "id": 2,
        "name": "Mahroof Fawas Mohamed",
        "role": "NDT",
        "degree": "NCDT, IQED Quantity Surveying",
        "image": Fawas
    },
    {
        "id": 3,
        "name": "Sahan Randika",
        "role": "NDT",
        "degree": "NDT Civil Engineering",
        "image": Sahan
    }
];

const HNDE = [
    {
        "id": 1,
        "name": "Nadaraja Kirushna",
        "role": "HNDE",
        "degree": "HND in Civil, National Diploma in HRM",
        "image": Nadaraja
    },
    {
        "id": 2,
        "name": "Jayaganesh Arunraam",
        "role": "HNDE",
        "degree": "HND in Building Services Technology, Diploma in Quantity Surveying",
        "image": Arunram
    },
    {
        "id": 3,
        "name": "Maheshika Lakshani",
        "role": "HNDE",
        "degree": "HNDE (Civil)",
        "image": Lakshani
    }
];

const Supervisors = [
    {
        "id": 1,
        "name": "Krishnapillai Shanthakumar",
        "role": "Work Supervisor",
        "degree": "",
        "image": Template
    },
    {
        "id": 2,
        "name": "Sinnarasa Sojithkanth",
        "role": "Work Supervisor",
        "degree": "NVQ CS5",
        "image": Sinnarasa
    },
    {
        "id": 3,
        "name": "Faizar Rayan",
        "role": "Work Supervisor",
        "degree": "NVQ CS5",
        "image": Rayan
    }
];

const Accountant = [
    {
        "id": 1,
        "name": "Mr.Pathmarajah",
        "role": "Accountant",
        "degree": "BSc Hons Civil Engineering, University of Jaffna",
        "image": Template
    },
    {
        "id": 2,
        "name": "Ninthusha Jeyakanth",
        "role": "Accountant Assistant",
        "degree": "",
        "image": Nithuha
    },
    {
        "id": 3,
        "name": "Luksha Sunmugarasa",
        "role": "Accounts Clerk",
        "degree": "",
        "image": Luksha
    }
];


export { Directors, Heads, Architects, EngineeringTrainees, TechnicalOfficers, Supervisors, NDT, HNDE, Surveyers, Accountant };
