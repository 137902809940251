import Gr1 from "../assets/images/Ongoing-Project/GR-Plazza/Rectangle 292.jpg"
import Gr2 from "../assets/images/Ongoing-Project/GR-Plazza/Rectangle 295.jpg"
import Gr3 from "../assets/images/Ongoing-Project/GR-Plazza/Rectangle 298.jpg"
import Gr4 from "../assets/images/Ongoing-Project/GR-Plazza/Rectangle 301.jpg"
import Gr5 from "../assets/images/Ongoing-Project/GR-Plazza/Rectangle 304.jpg"
import Gr6 from "../assets/images/Ongoing-Project/GR-Plazza/Rectangle 469.jpg"
import Gr7 from "../assets/images/Ongoing-Project/GR-Plazza/Rectangle 470.jpg"
import comehome1 from "../assets/images/Ongoing-Project/Come-Home/Rectangle 293.jpg"
import comehome2 from "../assets/images/Ongoing-Project/Come-Home/Rectangle 296.jpg"
import comehome3 from "../assets/images/Ongoing-Project/Come-Home/Rectangle 305.jpg"
import ehedfoodcity1 from "../assets/images/Ongoing-Project/Ehed-Food-City/Rectangle 141.jpg"
import ehedfoodcity2 from "../assets/images/Ongoing-Project/Ehed-Food-City/Rectangle 278.jpg"
import ehedfoodcity3 from "../assets/images/Ongoing-Project/Ehed-Food-City/Rectangle 279.jpg"
import ehedfoodcity4 from "../assets/images/Ongoing-Project/Ehed-Food-City/Rectangle 483.jpg"
import ehedfoodcity5 from "../assets/images/Ongoing-Project/Ehed-Food-City/Rectangle 484.jpg"
import ehedfoodcity6 from "../assets/images/Ongoing-Project/Ehed-Food-City/Rectangle 485.jpg"
import ehedfoodcity7 from "../assets/images/Ongoing-Project/Ehed-Food-City/Rectangle 486.jpg"
import ehedfoodcity8 from "../assets/images/Ongoing-Project/Ehed-Food-City/Rectangle 290.jpg"
import directorquarters1 from "../assets/images/Ongoing-Project/Director-Quaters/Rectangle 379.jpg"
import directorquarters2 from "../assets/images/Ongoing-Project/Director-Quaters/Rectangle 397.jpg"
import directorquarters3 from "../assets/images/Ongoing-Project/Director-Quaters/Rectangle 415.jpg"
import directorquarters4 from "../assets/images/Ongoing-Project/Director-Quaters/Rectangle 433.jpg"
import mathuragam1 from "../assets/images/Ongoing-Project/Mathuragam/Rectangle 373.jpg"
import mathuragam2 from "../assets/images/Ongoing-Project/Mathuragam/Rectangle 391.jpg"
import mathuragam3 from "../assets/images/Ongoing-Project/Mathuragam/Rectangle 409.jpg"
import mathuragam4 from "../assets/images/Ongoing-Project/Mathuragam/Rectangle 427.jpg"
import nithiyananthahome1 from "../assets/images/Ongoing-Project/Nithiyanantha-Home/Rectangle 367.jpg"
import nithiyananthahome2 from "../assets/images/Ongoing-Project/Nithiyanantha-Home/Rectangle 385.jpg"
import nithiyananthahome3 from "../assets/images/Ongoing-Project/Nithiyanantha-Home/Rectangle 403.jpg"
import chestclinic1 from "../assets/images/Ongoing-Project/Chest-clinic/Rectangle 287.jpg"
import chestclinic2 from "../assets/images/Ongoing-Project/Chest-clinic/Rectangle 288.jpg"
import chestclinic3 from "../assets/images/Ongoing-Project/Chest-clinic/Rectangle 289.jpg"
import thevakipillai1 from "../assets/images/Ongoing-Project/Arunagiri-Street/Rectangle 294.jpg"
import thevakipillai2 from "../assets/images/Ongoing-Project/Arunagiri-Street/Rectangle 297.jpg"
import thevakipillai3 from "../assets/images/Ongoing-Project/Arunagiri-Street/Rectangle 300.jpg"
import thevakipillai4 from "../assets/images/Ongoing-Project/Arunagiri-Street/Rectangle 303.jpg"
import common from "../assets/images/RandomHouse.jpg"



const ongoingProject = [
    {
        "id": 1,
        "title": "GR PLAZA",
        "work_place": {
            "client": "Mr. Gnanasegaram",
            "projectValue": 600,
            "initiation": "Jun 01, 2023",
            "completion": "Oct 30, 2024",
            "supervisors": [
                { "name": "Gobishankar T." },
                { "name": "Jayalakshan S." },
                { "name": "John Keshanth J." },
                { "name": "Maheshika L." }
            ]
        },
        "address": "NO:406, Main street, Trincomalee",
        "images": [Gr1, Gr2, Gr3, Gr4, Gr5, Gr6, Gr7]
    },
    {
        "id": 2,
        "title": "Come Home Shop Building",
        "work_place": {
            "client": "Mr. S.A. Selvanayagam",
            "projectValue": 15,
            "initiation": "Mar 13, 2024",
            "completion": "Jul 13, 2024",
            "supervisors": [
                { "name": "Kirushna N.", }
            ]
        },
        "address": "Trincomalee",
        "images": [comehome1, comehome2, comehome3]
    },
    {
        "id": 3,
        "title": "EHED Caritas Foodcity, Food corner, Restvilla",
        "work_place": {
            "client": "---",
            "projectValue": 85,
            "initiation": "Nov 02, 2023",
            "completion": "Aug 01, 2024",
            "supervisors": [
                { "name": "Sajithkanth S." },
                { "name": "Fawas Mohamed M." }
            ]
        },
        "address": "Trincomalee",
        "images": [ehedfoodcity1, ehedfoodcity2, ehedfoodcity3, ehedfoodcity4, ehedfoodcity5, ehedfoodcity6, ehedfoodcity7, ehedfoodcity8]
    },
    {
        "id": 4,
        "title": "Navaladi Costruction Of Two Storied Boy's hostal building",
        "work_place": {
            "client": "Ar Thiyavadduvan",
            "projectValue": 28,
            "initiation": "Mar 03, 2024",
            "completion": "Jul 23, 2024",
            "supervisors": [
                { "name": "Shweedhan B." }
            ]
        },
        "address": "Navaladi",
        "images": [common]
    },
    {
        "id": 5,
        "title": "Renovation of director quarters DGH",
        "work_place": {
            "client": "Provincial Director of Health service",
            "projectValue": 9.8,
            "initiation": "Jun 01, 2024",
            "completion": "Jul 31, 2024",
            "supervisors": [
                { "name": "Sajithkanth S." }
            ]
        },
        "address": "General Hospital, Trincomalee",
        "images": [directorquarters1, directorquarters2, directorquarters3, directorquarters4]
    },
    {
        "id": 6,
        "title": "Proposed House for Mr. Satchithananthan",
        "work_place": {
            "client": "Mr. Satchithananthan",
            "projectValue": 19.7,
            "initiation": "Jul 12, 2024",
            "completion": "Nov 12, 2024",
            "supervisors": [
                { "name": "Arunraam J." }
            ]
        },
        "address": "Trincomalee",
        "images": [mathuragam1, mathuragam2, mathuragam3, mathuragam4]
    },
    {
        "id": 7,
        "title": "Construction of Collecting and Packing Center at Pomegranate Cluster in Kaluwanchikudy Ds Division in Batticaloa",
        "work_place": {
            "client": "Ministry of Agriculture",
            "projectValue": 20,
            "initiation": "May 01, 2024",
            "completion": "Aug 30, 2024",
            "supervisors": [
                { "name": "Shweedhan B." }
            ]
        },
        "address": "Kaluwanchikudy, Batticaloa District",
        "images": [common]
    },
    {
        "id": 8,
        "title": "Balance Work of Construction of Store for Storing Agriculture Inputs. Machinery and Equipment in Karadiyanaru Batticaloa District",
        "work_place": {
            "client": "Ministry of Agriculture",
            "projectValue": 2.5,
            "initiation": "Oct 04, 2023",
            "completion": "Jul 01, 2024",
            "supervisors": [
                { "name": " Shweedhan B." }
            ]
        },
        "address": "Karadiyanaru, Batticaloa District",
        "images": [common]
    },
    {
        "id": 9,
        "title": "Proposed House for Mr. Nythianandam",
        "work_place": {
            "client": "Mr. Nythianandam",
            "projectValue": 25.5,
            "initiation": "Feb 27, 2024",
            "completion": "Jul 10, 2024",
            "supervisors": [
                { "name": "Mohamed Hashan" }
            ]
        },
        "address": "NO: 710, 10 Ehambaram Road, Murugapuri, Trincomalee",
        "images": [nithiyananthahome1, nithiyananthahome2, nithiyananthahome3]
    },
    {
        "id": 10,
        "title": "Proposed House for Mr. Manoj",
        "work_place": {
            "client": "Mr. Manoj",
            "projectValue": 15,
            "initiation": "Oct 01, 2023",
            "completion": "Aug 30, 2024",
            "supervisors": [
                { "name": "Pahirathan A." }
            ]
        },
        "address": "Trincomalee",
        "images": [common]
    },
    {
        "id": 11,
        "title": "Proposed House for Mrs. N.Thevakipillai",
        "work_place": {
            "client": "MRS. N.Thevakipillai",
            "projectValue": 60,
            "initiation": "Feb 12, 2024",
            "completion": "Nov 12, 2024",
            "supervisors": [
                { "name": "Vinojan P." },
                { "name": "Rachsuthan V." }
            ]
        },
        "address": "NO: 83 Arunagiri Street, Trincomalee",
        "images": [thevakipillai1, thevakipillai2, thevakipillai3, thevakipillai4]
    },
    {
        "id": 12,
        "title": "Chest Clinic - Stage 03",
        "work_place": {
            "client": "Department of Buildings",
            "projectValue": 22.5,
            "initiation": "Jun 07, 2024",
            "completion": "Oct 07, 2024",
            "supervisors": [
                { "name": "Sahan Randika" }
            ]
        },
        "address": "Manaiyaveli, Trincomalee",
        "images": [chestclinic1, chestclinic2, chestclinic3]
    }
]

export default ongoingProject;
